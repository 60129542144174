<template>
	<div class="mhome">
        <el-result icon="success" :title="$t('mobile.successMsg.evaluate')" subTitle="">
            <template slot="extra">
                <router-link :to="{ name: 'mUserCenter' }" class="item-link">
                    <el-button size="medium">{{ $t('mobile.actions.backHome') }}</el-button>
                </router-link>
                <router-link :to="{ name: 'mEvaluateList', params: { type: 'have' }}" class="item-link">
                    <el-button type="primary" size="medium">{{ $t('mobile.actions.viewEvaluate') }}</el-button>
                </router-link>
            </template>
        </el-result>
	</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.mhome {
    background-color: #ffffff;
    .item-link {
        margin: 0 5px;
    }
}
</style>